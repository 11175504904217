import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ConnectButton from 'components/ConnectButton'

// import { useIsCorrectAddress } from 'state/user/hooks'

import JustLogoIcon from 'assets/images/just_logo.svg'
import JustTextIcon from 'assets/images/just_text.svg'
// import NotificationIcon from 'assets/images/icons/header-icons/Notifications.svg'
// import LanguageIcon from 'assets/images/icons/header-icons/Language.svg'
import LogoutBtnIcon from 'assets/images/icons/logout.svg'

import { useIsUserAuthorized, useUserTenementTokenSales, useUserDetails } from 'state/user/hooks'

import { setAuthToken } from 'state/user/reducer'

import styles from './styles.module.scss'

const Header = () => {
  const dispatch = useDispatch()
  const isUserAuthorized = useIsUserAuthorized()

  const tokenSales = useUserTenementTokenSales()

  const userDetails = useUserDetails()

  const tokenSale = tokenSales[0]

  const logout = useCallback(() => {
    dispatch(setAuthToken(null))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.rightSection}>
          <a href="/">
            <div className={styles.logoWrapper}>
              <div className={styles.iconWrapper}>
                <img src={JustLogoIcon} alt="" />
              </div>
              <div className={styles.textWrapper}>
                <img src={JustTextIcon} alt="" />
              </div>
            </div>
          </a>

          <div className={styles.navItems}>
            <NavLink
              to="/about"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>About</p>
            </NavLink>
            <NavLink
              to="/first-property"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>First Property</p>
            </NavLink>

            <NavLink
              to="/marketplace"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>Marketplace</p>
            </NavLink>
            <NavLink
              to="/wallet"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>Wallet</p>
            </NavLink>
            <NavLink
              to="/p2p-exchange"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>P2P Exchange</p>
            </NavLink>

            <NavLink
              to="/request-tokensale"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>Request Tokensale</p>
            </NavLink>

            {isUserAuthorized && userDetails?.is_tenant ? (
              tokenSale ? (
                <NavLink
                  to={`/rent-payment/${tokenSale.id}`}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles['pending']
                      : isActive
                      ? styles['active']
                      : ''
                  }
                >
                  <p className={styles.navItem}>Rent Pay</p>
                </NavLink>
              ) : (
                <NavLink
                  to="/select-rent-property"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? styles['pending']
                      : isActive
                      ? styles['active']
                      : ''
                  }
                >
                  <p className={styles.navItem}>Rent Pay</p>
                </NavLink>
              )
            ) : (
              ' '
            )}
          </div>
        </div>
        {isUserAuthorized ? (
          <div className={styles.leftSection}>
            {/* <div className={styles.btnsWrapper}>
              <div className={styles.btn}>
                <img src={NotificationIcon} alt="notifications" />
              </div>

              <div className={styles.btn}>
                <img src={LanguageIcon} alt="change language" />
              </div>
            </div> */}

            <ConnectButton />

            <div className={styles.profile} onClick={logout}>
              <img src={LogoutBtnIcon} alt="user avatar" />
            </div>
          </div>
        ) : (
          <div className={styles.leftSection}>
            <NavLink className={styles.signIn} to="/sign-in">
              Sign In
            </NavLink>
            <NavLink className={styles.signUp} to="/sign-up">
              Sign Up
            </NavLink>
          </div>
        )}
        {/* <div className={styles.leftSection}>
          <div className={styles.btnsWrapper}>
            <div className={styles.btn}>
              <img src={NotificationIcon} alt="notifications" />
            </div>

            <div className={styles.btn}>
              <img src={LanguageIcon} alt="change language" />
            </div>
          </div>

          <div className={styles.balanceWrapper}>
            <div className={styles.balanceInfo}>
              <div className={styles.title}>Balance</div>

              <div className={styles.amount}>$241,003.00</div>
            </div>

            <div className={styles.buyBtn}>
              <img src={buyBtnIcon} alt="buy nativa currency" />
            </div>
          </div>

          <div className={styles.profile}>
            <img src={UserStubImg} alt="user avatar" />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Header
