import { useState, useCallback, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import JustLogoIcon from 'assets/images/just_logo.svg'
import JustTextIcon from 'assets/images/just_text.svg'
import MenuIcon from '../../assets/images/icons/menu.svg'

import { useIsUserAuthorized, useUserTenementTokenSales, useUserDetails } from 'state/user/hooks'
import { setAuthToken } from 'state/user/reducer'

import styles from './styles.module.scss'
import ConnectButton from 'components/ConnectButton'

const MobileHeader = () => {
  const dispatch = useDispatch()
  const isUserAuthorized = useIsUserAuthorized()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const tokenSales = useUserTenementTokenSales()

  const userDetails = useUserDetails()

  const tokenSale = tokenSales[0]

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const logout = useCallback(() => {
    dispatch(setAuthToken(null))
    setIsOpen(false)
  }, [dispatch])

  useEffect(() => {
    setIsOpen(false)
  }, [location.pathname])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logoWrapper}>
          <div className={styles.iconWrapper}>
            <img src={JustLogoIcon} alt="" />
          </div>
          <div className={styles.textWrapper}>
            <img src={JustTextIcon} alt="" />
          </div>
        </div>
        {isUserAuthorized && <div className={styles.connectWrapper}><ConnectButton /></div>}
        <div className={styles.menuButton} onClick={toggleMenu}>
          <img src={MenuIcon} alt="" />
        </div>
      </div>
      {' '}
      {isOpen && (
        <div className={styles.menuItems}>
          <NavLink
            to="/about"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>About</p>
          </NavLink>
          <NavLink
            to="/first-property"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>First Property</p>
          </NavLink>
          <NavLink
            to="/marketplace"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>Marketplace</p>
          </NavLink>
          <NavLink
            to="/wallet"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>Wallet</p>
          </NavLink>
          <NavLink
            to="/p2p-exchange"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>P2P Exchange</p>
          </NavLink>

          <NavLink
            to="/request-tokensale"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>Request Tokensale</p>
          </NavLink>
          {/* <NavLink
            to="/select-rent-property"
            className={({ isActive, isPending }) =>
              isPending ? styles['pending'] : isActive ? styles['active'] : ''
            }
          >
            <p className={styles.navItem}>Rent Pay</p>
          </NavLink> */}
          { isUserAuthorized && userDetails?.is_tenant ? (tokenSale ?
            <NavLink
              to={`/rent-payment/${tokenSale.id}`}
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>Rent Pay</p>
            </NavLink>
            :
            <NavLink
              to="/select-rent-property"
              className={({ isActive, isPending }) =>
                isPending ? styles['pending'] : isActive ? styles['active'] : ''
              }
            >
              <p className={styles.navItem}>Rent Pay</p>
            </NavLink>) : (' ')
          }
          {isUserAuthorized ? (
            <div className={styles.logoutBtn} onClick={logout}>
              <p className={styles.navItem}>Logout</p>
            </div>
          ) : (
            <div className={styles.mobileSection}>
              <NavLink className={styles.signIn} to="/sign-in">
                Sign In
              </NavLink>
              <NavLink className={styles.signUp} to="/sign-up">
                Sign Up
              </NavLink>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MobileHeader
