import styles from './styles.module.scss'
import { Card } from '../../components/UI/Card'
import { Button } from '../../components/UI/Button'
// import LocationIcon from '../../assets/images/icons/location.svg'
import SqftIcon from '../../assets/images/icons/sqft.svg'
import BedroomIcon from '../../assets/images/icons/bedroom.svg'
import HouseIcon from '../../assets/images/icons/house.svg'
import BathroomIcon from '../../assets/images/icons/bathroom.svg'
import FloorIcon from '../../assets/images/icons/floor.svg'
import FirstPropPhoto from 'assets/images/photos/first_prop.png'
import PirceIcon from '../../assets/images/icons/price.svg'
import RentIcon from '../../assets/images/icons/rent.svg'
import RoiIcon from '../../assets/images/icons/roi.svg'

const PropertyLaunchPage = () => {
  const property = {
    imageUrl: FirstPropPhoto, // Replace with actual path
    title: '4311 Cobalt Cross St, KATY, TX 77493',
    description:
      'A beautiful and spacious 5-bedroom, 4.5-bathroom single-family home located in a prime neighborhood of Houston. This will be the first property to launch on the EdificeX platform. You can find details about the property below.',
    fairMarketRent: '$3,750/month',
    fairMarketValue: '$550,000',
    roi: '4.11% annually + 35% over 7 years',
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <Card>
          <h2 className={styles.title}>
            🚀 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; EdificeX First Property Launch
            &nbsp;&nbsp;&nbsp;&nbsp; 🚀
          </h2>
          <h3 className={styles.subtitle}>
            A Milestone in Ethical Real Estate Financing and Investment
          </h3>
          <br />
          <br />
          <img
            src={property.imageUrl}
            alt="Launch Property"
            className={styles.image}
          />
          <div className={styles.content}>
            <div className={styles.infoBox}>
              <br />
              <h2 className={styles.subtitle}>{property.title}</h2>
              <br />
              <p className={styles.description}>{property.description}</p>
              <br />
            </div>
            <br />
            {/* <div className={styles.propSectionGrid}> */}
            <div className={styles.infoIcons}>
              <div className={styles.infoIcon}>
                <img src={HouseIcon} alt="house" />
                <div className={styles.desc}>House</div>
              </div>
              <div className={styles.infoIcon}>
                <img src={SqftIcon} alt="sqft" />
                <div className={styles.desc}>3,786 SQFT</div>
              </div>
              <div className={styles.infoIcon}>
                <img src={FloorIcon} alt="floor" />
                <div className={styles.desc}>2 Floors</div>
              </div>
              <div className={styles.infoIcon}>
                <img src={BedroomIcon} alt="bedroom" />
                <div className={styles.desc}>5 Bedrooms</div>
              </div>
              <div className={styles.infoIcon}>
                <img src={BathroomIcon} alt="bathroom" />
                <div className={styles.desc}>4.5 Bathrooms</div>
              </div>
            </div>
            {/* </div> */}
            <br />
            <hr />
            <br />
            <div className={styles.sectionGrid}>
              <div className={styles.statBox}>
                <h3>Fair Market Value</h3>
                <br />
                <img src={PirceIcon} alt="price" />
                <br />
                <br />
                <p>{property.fairMarketValue}</p>
              </div>
              <div className={styles.statBox}>
                <h3>Fair Market Rent</h3>
                <br />
                <img src={RentIcon} alt="rent" />
                <br />
                <br />
                <p>{property.fairMarketRent}</p>
              </div>
              <div className={styles.statBox}>
                <h3>Projected ROI</h3>
                <br />
                <img src={RoiIcon} alt="ROI" />
                <br />
                <br />
                <p>{property.roi}</p>
              </div>
            </div>
            <br />
            <br />
            <br />
            <Button
              className={styles.ctaButton}
              onClick={() => {
                window.location.href =
                  'https://www.jotform.com/form/250254666196160'
              }}
            >
              Fill Out The Investor Interest Form
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PropertyLaunchPage

// import React from 'react'
// import { Card, CardContent } from '@/components/ui/card'
// import { Button } from '@/components/ui/button'
// import { Badge } from '@/components/ui/badge'

// const PropertyLaunchPage = () => {
//   const property = {
//     imageUrl: '/images/properties/launch-property.jpg', // Replace with actual path
//     title: '123 Maple Street, Houston, TX',
//     description:
//       'A beautifully renovated 3-bedroom, 2-bathroom single-family home located in a prime neighborhood of Houston. This will be the first property to launch on the EdificeX platform.',
//     fairMarketRent: '$2,100/month',
//     fairMarketValue: '$310,000',
//     roi: '5.6% annually',
//   }

//   return (
//     <div className="max-w-5xl mx-auto p-6">
//       <h1 className="text-3xl font-bold mb-4 text-center">
//         EdificeX Property Launch
//       </h1>
//       <Card className="shadow-xl">
//         <img
//           src={property.imageUrl}
//           alt="Launch Property"
//           className="w-full h-96 object-cover rounded-t-xl"
//         />
//         <CardContent className="p-6">
//           <h2 className="text-2xl font-semibold mb-2">{property.title}</h2>
//           <p className="text-gray-700 mb-4">{property.description}</p>

//           <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
//             <div className="bg-gray-100 p-4 rounded-lg">
//               <h3 className="font-semibold">Fair Market Rent</h3>
//               <p className="text-lg">{property.fairMarketRent}</p>
//             </div>
//             <div className="bg-gray-100 p-4 rounded-lg">
//               <h3 className="font-semibold">Fair Market Value</h3>
//               <p className="text-lg">{property.fairMarketValue}</p>
//             </div>
//             <div className="bg-gray-100 p-4 rounded-lg">
//               <h3 className="font-semibold">Projected ROI</h3>
//               <p className="text-lg">{property.roi}</p>
//             </div>
//           </div>

//           <Button className="w-full">Learn More & Invest</Button>
//         </CardContent>
//       </Card>
//     </div>
//   )
// }

// export default PropertyLaunchPage
