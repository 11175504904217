import { ChainId } from 'web3/chains'

export const ADDRESS_REGISTRY_ADDRESS = {
  [ChainId.EDIFICE]: '0x9F8885CDb7f19491Cf5995743d151ccD7b599006',
  [ChainId.TESTNET]: '',
  [ChainId.LOCAL]: '0xA4899D35897033b927acFCf422bc745916139776',
}

export const EXCHANGE_ADDRESS = {
  [ChainId.EDIFICE]: '0x828ac751080a313D71769bD654Ce76eEceEa8B44',
  [ChainId.EDIFICE_TESTNET]: '',
  [ChainId.LOCAL]: '0x1c85638e118b37167e9298c2268758e058DdfDA0',
}
